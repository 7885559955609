const sponsor = [
  {
    path: '/sponsor/:sponsor_id',
    name: 'sponsor-home',
    component: () => import('@/pages/home/sponsor.vue'),
    meta: {
      title: 'Dashboard',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/programs',
    name: 'sponsor-programs',
    component: () => import('@/pages/programs/ProgramList.vue'),
    meta: {
      title: 'Programs',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/programs/:charity_id',
    name: 'sponsor-program-details',
    component: () =>
      import('@/pages/settings/account-settings/AccountSettings.vue'),
    meta: {
      title: 'Account Settings',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/approvals/billpay',
    name: 'sponsor-approvals-billpay',
    component: () => import('@/pages/banking/billpay/bills.vue'),
    meta: {
      title: 'Bill Pay',
      resource: 'sponsor',
    },
    props: true,
  },
  {
    path: '/sponsor/:sponsor_id/approvals/reimbursements',
    name: 'sponsor-approvals-reimbursements',
    component: () => import('@/pages/banking/reimbursement.vue'),
    meta: {
      title: 'Reimbursements',
      resource: 'sponsor',
    },
    props: true,
  },
  {
    path: '/sponsor/:sponsor_id/applications',
    name: 'sponsor-applications',
    component: () => import('@/pages/programs/ApplicationList.vue'),
    meta: {
      title: 'Applications',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/subscriptions',
    name: 'sponsor-subscriptions',
    component: () => import('@/pages/programs/SubscriptionList.vue'),
    meta: {
      title: 'Subscriptions',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/applications/:application_id',
    name: 'sponsor-application-details',
    component: () => import('@/pages/onboarding/ApplicationReview.vue'),
    meta: {
      title: 'Application Review',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/applications/:application_id/contract/:contract_id',
    name: 'sponsor-application-contract',
    component: () =>
      import('@/pages/onboarding/ApplicationSponsorContract.vue'),
    meta: {
      title: 'Edit Contract Template',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/banking/overview',
    name: 'sponsor-banking-overview',
    component: () =>
      import('@/pages/sponsor/banking/overview.vue'),
    meta: {
      title: 'Banking',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/banking/reports',
    name: 'sponsor-banking-reports',
    component: () =>
      import('@/pages/sponsor/banking/reports.vue'),
    meta: {
      title: 'Banking',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/banking/:vault_id',
    name: 'sponsor-banking-account-details',
    component: () => import('@/pages/sponsor/banking/account-details.vue'),
    meta: {
      title: 'Account Details',
      resource: 'sponsor',
    },
    props: route => ({ key: route.params.vault_id }),
  },
  {
    path: '/sponsor/:sponsor_id/banking/transactions',
    name: 'sponsor-banking-transactions',
    component: () => import('@/pages/banking/index.vue'),
    meta: {
      title: 'Transactions',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/compliance/overview-beta',
    name: 'sponsor-compliance-home',
    component: () => import('@/pages/sponsor/compliance/index.vue'),
    meta: {
      title: 'Compliance',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/compliance/donors',
    name: 'sponsor-compliance-donors',
    component: () => import('@/pages/donors/index.vue'),
    meta: {
      title: 'Donors',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/compliance/vendors',
    name: 'sponsor-compliance-vendors',
    component: () => import('@/pages/sponsor/compliance/vendors.vue'),
    meta: {
      title: 'Vendors',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/banking/spending-controls',
    name: 'sponsor-banking-spending-controls',
    component: () => import('@/pages/sponsor/banking/spending-controls.vue'),
    meta: {
      title: 'Spending Controls',
      resource: 'sponsor',
    },
  },
  {
    path: '/sponsor/:sponsor_id/tax-reporting/program-1099-data',
    name: 'sponsor-program-1099-data',
    component: () => import('@/pages/sponsor/tax-reporting/program-1099-data.vue'),
    meta: {
      title: 'Program 1099 Data',
      resource: 'sponsor',
    },
  },

  // {
  //   path: '/sponsor/:sponsor_id/tax-reporting/990',
  //   name: 'sponsor-990',
  //   component: () => import('@/pages/sponsor/tax-reporting/990.vue'),
  //   meta: {
  //     title: 'Sponsor 990',
  //     resource: 'sponsor',
  //   },
  // },
]

export default sponsor
